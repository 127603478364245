// AboutUs.js

import React from 'react';
import './AboutUs.css'; // Import CSS file for styling
import aboutUsImage from '../../Assets/images/parkVimages/customer and reception.jpg';

const AboutUs = () => {
  return (
    <div className="about-us-section">
      <div className="about-us-card">
        <div className="about-us-text">
          <h2>About Park Ville Medical Center.</h2>
          <p>
            At Park Ville, we understand that accidents and illness can happen anytime; that’s why we provide services and treatment when and where it’s convenient for you.
            We are dedicated to providing you with excellent and prompt urgent care services with a timely diagnosis, accurate medical information, and a personalized touch. Our compassionate and experienced staff will work hard to place you or your family member back on the road to a swift recovery.
            We are dedicated to providing you with excellent and prompt urgent care services.
          </p>
          <a href="/#" className="read-more-button">Discover More</a>
        </div>
        <div className="about-us-image">
          <img src={aboutUsImage} alt="About Us" /> 
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
