import React, { useState, useEffect } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [submenuType, setSubmenuType] = useState(null);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const toggleSubmenu = (type) => {
    if (submenuType === type) {
      setShowSubmenu(!showSubmenu);
    } else {
      setShowSubmenu(true);
      setSubmenuType(type);
    }
  };

  useEffect(() => {
    const yearInterval = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000);

    return () => clearInterval(yearInterval);
  }, []);

  return (
    <footer className="footer">
      <div className="footer-section">
      <a href="/" className="home-link">
          <h4>Park Ville Medical Center</h4>
          <p>PREMIUM CARE CLOSER TO YOU</p>
        </a>
      </div>

      <div className="footer-section">
        <h4>Quick Links</h4>
        <ul>
          <li><a href="/about-clinic" style={{ textDecoration: 'none', color: '#fff' }}>About Us</a></li>
          <li><a href="/general-consultation/services" style={{ textDecoration: 'none', color: '#fff' }}>General Consultation</a></li>
          <li><a href="/specialist-consultation" style={{ textDecoration: 'none', color: '#fff' }}>Specialist Consultation</a></li>
          <li><a href="/pediatric" style={{ textDecoration: 'none', color: '#fff' }}>Pediatric Clinic</a></li>
          <li><a href="/well-baby-clinic" style={{ textDecoration: 'none', color: '#fff' }}>Well Baby Clinic</a></li>
          <li><a href="/minor-procedures" style={{ textDecoration: 'none', color: '#fff' }}>Minor Procedures</a></li>
          <li><a href="/home-services" style={{ textDecoration: 'none', color: '#fff' }}>Home Care</a></li>
          <li><a href="/imaging" style={{ textDecoration: 'none', color: '#fff' }}>Imaging Services</a></li>
          <li><a href="/lab-services" style={{ textDecoration: 'none', color: '#fff' }}>Lab Based Services</a></li>
          <li><a href="/emergency-services" style={{ textDecoration: 'none', color: '#fff' }}>Emergency Services</a></li>
          <li><a href="/ante-natal-post-natal-services" style={{ textDecoration: 'none', color: '#fff' }}>Ante Natal & Post Natal Services</a></li>
          <li><a href="/Maternity-services" style={{ textDecoration: 'none', color: '#fff' }}>Maternity Services</a></li>
        </ul>
      </div>

      <div className="footer-section contact-us-section">
        <h4>Contact Us</h4>
        <ul>
          <li>City Park Estate, House 62</li>
          <li>0753 681 716 (Daily 8am - 8pm)</li>
          <li>info@parkvillemedical.org</li>
        </ul>

        <div className="social-icons1">
          <a href="#" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
          <a href="#" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
          <a href="#" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
          <a href="#" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
        </div>
    
        <div className="bottom-line" id="current-year">
          © {currentYear} Park Ville Medical. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
