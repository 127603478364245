// HeroSection.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './hero.css';
import slider1 from '../../Assets/images/parkVimages/slider1.jpg';
import slider2 from '../../Assets/images/parkVimages/slider2.jpg';
import slider4 from '../../Assets/images/parkVimages/Slider4.jpg';
import slider3 from '../../Assets/images/parkVimages/Slider3.jpg';
import slider5 from '../../Assets/images/new park images/wellbaby image.PNG';

const HeroSection = () => {
  const sliderSettings = {
    arrows: true,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    fade: false,
    cssEase: 'linear',
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <div className="slick-custom-arrow slick-next" />,
    prevArrow: <div className="slick-custom-arrow slick-prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          speed: 300,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          speed: 300,
        },
      },
    ],
  };

  return (
    <div className="hero-section">
      <Slider {...sliderSettings}>
        {[
          {
            image: slider1,
            title: 'Welcome to Park Ville Medical Centre.',
            subTitle: 'Your Path to Exceptional Healthcare Begins Here. Our expert team conducts precise tests, tailoring care plans for your unique needs. We are committed to patient-centered excellence, your trusted partner on the path to well-being.PREMIUM HEALTHCARE CLOSER TO YOU.',
            linkTo: '/about-clinic',
            buttonText: 'About Us',
          },
          {
            image: slider2,
            title: 'Your well-being, our priority.',
            subTitle: 'Our doctors, nurses and departmental staff comprise the best within their fields. YOUR HEALTH IS OUR PRIDE.',
            linkTo: '/specialist-consultation',
            buttonText: 'Read More',
          },
          {
            image: slider4,
            title: 'Empowering Childhood Health: Compassionate Pediatric Care at Park Ville.',
            subTitle: 'Fostering healthy childhoods with compassionate pediatric care, every step.',
            linkTo:"/book-appointment", // Change to your donation link
            buttonText: 'Book Appointment',
          },
          {
            image: slider5,
            title: 'Introducing Our Well Baby Clinic.',
            subTitle: 'Your Childs Health, Our Priority. From infancy to young adulthood, we are dedicated to your childs well-being. Step into our Well Baby Clinic, where personalized care meets expert guidance.',
            linkTo:"/well-baby-clinic", // Change to your donation link
            buttonText: 'Read More',
          },
          {
            image: slider3,
            title: 'Improving The Quality Of Your Healthcare.',
            subTitle: 'At the heart of your health journey, our commitment shines bright. Your care is our priority, and we are here to illuminate the path to a healthier, happier you.',
            linkTo:"/book-appointment", // Change to your donation link
            buttonText: 'Book Appointment',
          },
        ].map((item, index) => (
          <div key={index} className="slider-image">
            <img src={item.image} alt={`Slider ${index + 1}`} className="zoom-in" />
            <div className="hero-text">
              <h1>{item.title}</h1>
              <h1>
                <span className="green-text">{item.subTitle}</span>
              </h1>
              <Link to={item.linkTo} className="hero-button2">
                {item.buttonText}
              </Link>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HeroSection;
