import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Testimonials.css';

const testimonies = [
  { name: 'Suad Hassan', comment: 'Amazing service and friendly staff... would recommend ⭐️⭐️⭐️⭐️⭐️' },
  { name: 'Derrick Odanga', comment: 'I love how the medical facilities are organized, staff is welcoming and the services here are at the best standards. Not forgetting about the good prices here, I wish to recommend highly for any patients who need a good turn around time.' },
  { name: 'David Muthiani', comment: 'I had a great experience with the specialist consultation service. Offers high-quality care and the specialists are knowledgeable and compassionate. A top-notch clinic for specialized medical needs! ⭐️⭐️⭐️⭐️⭐️' },
  { name: 'Yassin Jabir', comment: 'A very organized team, highly recommend ⭐️⭐️⭐️⭐️⭐️' },
  { name: 'Aisha Kibwana', comment: 'Impressive responsiveness and quick actions⭐️⭐️⭐️⭐️⭐️' },
  { name: 'Ikran Abdi', comment: 'The pediatricians are friendly, approachable, and genuinely care about the well-being of our child. Trustworthy care for every stage of childhood! ⭐️⭐️⭐️⭐️⭐️' },
  { name: 'Allan Juma', comment: 'Park Ville Medical Center exceeded my expectations. The doctors were attentive, thorough, and provided personalized care for my family. Highly recommended! ⭐️⭐️⭐️⭐️⭐️' },
  { name: 'Dianah Dorine', comment: 'The staff is knowledgeable, and the hospital is well-equipped to handle all aspects of pediatric care.⭐️⭐️⭐️⭐️⭐️' },
  { name: 'Shukri Abdillahi', comment: 'I was impressed with the prompt and efficient care I received. The staff acted quickly to address my concerns, and the facility is well-equipped to handle emergencies. ⭐️⭐️⭐️⭐️⭐️' },
  { name: 'Penelope Sadhu', comment: 'I was impressed with the efficiency and quality of care.⭐️⭐️⭐️⭐️⭐️' }
];

// Custom profile image component
const ProfileImage = ({ name }) => {
  const initials = name.split(' ').map(word => word.charAt(0)).join('');
  const colors = ['#FF6F61', '#6B5B95', '#88B04B', '#F7CAC9', '#92A8D1']; // You can add more colors as needed
  const randomColor = colors[Math.floor(Math.random() * colors.length)];

  return (
    <div className="profile-image" style={{ backgroundColor: randomColor }}>
      {initials}
    </div>
  );
};

const Testimonials = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 5000, // Set autoplay speed to 5 seconds
    prevArrow: <button className="slick-prev">&#10094;</button>, // Previous arrow
    nextArrow: <button className="slick-next">&#10095;</button>, // Next arrow
  };

  return (
    <div className="testimonial-slider-container">
      <h2 className="testimonialsTitle">Testimonials</h2>
      <Slider {...settings}>
        {testimonies.map((testimonial, index) => (
          <div key={index} className="testimonial-slide">
            <ProfileImage name={testimonial.name} />
            <p className="comment"><span className="quote">"</span>{testimonial.comment}<span className="quote">"</span></p>
            <p className="client-name">{testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
