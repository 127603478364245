import React, { useEffect, useRef, useState } from 'react';
import './OurServices.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import medicalConsultationImage from '../../Assets/images/parkVimages/generalconsultation.jpg';
import wellBabyClinicImage from '../../Assets/images/parkVimages/babayclinic.jpg';
import imagingServicesImage from '../../Assets/images/parkVimages/imagecheck.jpg';
import homeBasedServicesImage from '../../Assets/images/parkVimages/homecare.jpg';
import minorProceduresImage from '../../Assets/images/parkVimages/treatment.jpg';
import emergencyServicesImage from '../../Assets/images/parkVimages/emergency.jpg';
import labBasedServicesImage from '../../Assets/images/new park images/best lab image.jpeg';
import anteNatalPostNatalImage from '../../Assets/images/new park images/ante natal.jpeg';
import SpecialConsultationImage from '../../Assets/images/new park images/special consoltation.jpg';
import phamacyImage from '../../Assets/images/parkVimages/phamacy.jpg';
import pediatricImage from '../../Assets/images/new park images/wellbaby image.PNG';

const services = [
  {
    title: 'General Consultation',
    description: 'We provide all aspects of medical practice for your family, including general check-ups.',
    image: medicalConsultationImage,
    link: '/general-consultation/services',
  },
  {
    title: 'Specialist Consultation',
    description: 'Special clinic with substantial cost saving prices & quality standards.',
    image: SpecialConsultationImage,
    link: '/specialist-consultation',
  },
  {
    title: 'Pediatric Care',
    description: 'At Park Ville Medical Center, we understand the significance of selecting the right pediatrician for your childs well-being. Our comprehensive approach transcends mere treatment; we prioritize holistic health, guiding your childs growth from infancy to young adulthood.',
    image: pediatricImage, 
    link: '/pediatric',
  },
  {
    title: 'Pharmacy',
    description: 'We dispense quality medicines at all our pharmacy outlets at the best price possible.',
    image: phamacyImage, // Replace with the actual image for Pharmacy
    link: '/pharmacy',
  },
  {
    title: 'Well Baby Clinic',
    description: 'We are committed to providing personalized high-quality care for your child right from birth all the way to young adulthood.',
    image: wellBabyClinicImage,
    link: '/well-baby-clinic',
  },
  {
    title: 'Imaging Services',
    description: 'ParkVille offers comprehensive, state-of-the-art medical services to help provide early detection, diagnosis, and treatment to patients in a convenient, outpatient setting.',
    image: imagingServicesImage,
    link: '/imaging',
  },
  {
    title: 'Home Based Services',
    description: 'We have a pool of certified home nursing professionals who are qualified, skilled, and have committed to giving the best care. All are trained from reputable institutions.',
    image: homeBasedServicesImage,
    link: '/home-services',
  },
  {
    title: 'Minor Procedures',
    description: 'Minor surgical procedures are those that are minimally invasive. Small incisions are made that allow surgical tools to be inserted into the body.',
    image: minorProceduresImage,
  },
  {
    title: 'Emergency Services',
    description: 'We specialize in emergency services. Our emergency department handles all emergency cases and a wide range of diagnostic tests and minor surgical procedures.',
    image: emergencyServicesImage,
    link: '/emergency-services',
  },
  {
    title: 'Lab Based Services',
    description: 'We offer a comprehensive range of integrated solutions, including preclinical activities, Phase I-IV clinical trials,bioanalytical, and Pharmaceutical development.',
    image: labBasedServicesImage,
    link: '/lab-services',
  },
  {
    title: 'Ante Natal & Post Natal',
    description: 'We have options to suit all, covering all stages from pregnancy through to the early weeks after birth.',
    image: anteNatalPostNatalImage, 
    link: '/ante-natal-post-natal-services',
  },

  // Add links for other services similarly
  // ...
];

const OurServices = () => {
  const servicesRef = useRef();
  const titleRef = useRef();
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    AOS.init({
      duration: 1000,
      once: true,
    });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const servicesContainer = servicesRef.current;
    const titleElement = titleRef.current;

    const titleOffset = titleElement.offsetTop;
    const servicesOffset = servicesContainer.offsetTop;

    const onScroll = () => {
      const offset = window.scrollY + window.innerHeight;

      if (offset > titleOffset) {
        titleElement.classList.add('fade-up');
      }

      const serviceCards = servicesContainer.querySelectorAll('.service-card');
      serviceCards.forEach((card, index) => {
        const cardOffset = card.offsetTop + servicesOffset;

        if (offset > cardOffset) {
          card.classList.add('fade-up');
        }
      });
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className="servicesContainer">
      <h2 className="servicesTitle" ref={titleRef}>
        What We do
      </h2>
      <p>Improving The Quality of Your Life Through Better Health Care.</p>
      <div className="services" ref={servicesRef}>
        {services.map((service, index) => (
          <div className="serviceCard" key={index} data-aos="fade-up" data-aos-delay={`${index * 100 + 200}`}>
            <img src={service.image} alt={service.title} className="serviceImage" />
            <div className="serviceDetails">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
              <a href={service.link}><button className="find-outMoreButton">Find Out More</button></a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurServices;
