import React, { useState, useEffect } from 'react';
import './LoadTimeIndicator.css';
import logo from '../../Assets/logos/parkville logo.jpg';

const LoadTimeIndicator = () => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0); // Track loading progress

  useEffect(() => {
    const handleLoad = () => {
      let progressCounter = 0;
      const interval = setInterval(() => {
        progressCounter += 10; // Increment progress by 10% (adjust as needed)
        setProgress(progressCounter);
        if (progressCounter >= 100) {
          clearInterval(interval);
          setTimeout(() => {
            setLoading(false);
          }, 2000); // 2 seconds delay before hiding the spinner
        }
      }, 200); // Adjust interval time if needed for smoother progress
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <div className="load-time-indicator" style={{ display: loading ? 'flex' : 'none' }}>
      <img src={logo} alt="Loading" className="spinner" />
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
};

export default LoadTimeIndicator;
