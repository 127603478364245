import React from 'react';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import './ContactUs.css';

const ContactPage = () => {
  const handleWhatsAppClick = () => {
    // Replace the message parameter with the desired pre-filled message
    const message = encodeURIComponent('Hello! Park Ville medical center');
    window.location.href = `https://wa.me/+254753681716?text=${message}`;
  };

  return (
    <div className="contactPage">
      <div className="contactDetails">
        <h2>Contact Us</h2>
        <div className="contactInfo">
          <div className="info">
            <FaPhone className="icon10" />
            <p>0753 681 716 (Daily 8am - 8pm)</p>
          </div>
          <div className="info">
            <FaEnvelope className="icon10" />
            <p>info@parkvillemedical.org</p>
          </div>
        </div>
      </div>
      <div className="location">
        <h2>Location</h2>
        <div className="infoContact">
          <FaMapMarkerAlt className="icon10" />
          <p>City Park Estate</p>
          <p>Plot 62</p>
        </div>
      </div>
      <div className="ContactwhatsappButton">
        <h2>Chat with Us on WhatsApp</h2>
        <button onClick={handleWhatsAppClick}>
          <FaWhatsapp className="icon11" />
          Start Chat
        </button>
      </div>
    </div>
  );
};

export default ContactPage;
