// SidebarData.jsx
export const sidebarData = [

  {
    title2: 'Home',
    url2: '/',
    submenuItems2: [],
  },
  {
    title2: 'About Us',
    url2: '/about-clinic',
    submenuItems2: [],
  },

  {
    title2: 'Services',
    url2: '/#', 
    submenuItems2: [
      {
        title2: 'General Consultation',
        url2: '/general-consultation/services',
      },
      {
        title2: 'Specialist Consultation',
        url2: '/specialist-consultation',
      },
      {
        title2: 'Pediatric Clinic',
        url2: '/pediatric',
      },
      {
        title2: 'Well Baby Clinic',
        url2: '/well-baby-clinic',
      },
      {
        title2: 'Minor Procedures',
        url2: '/minor-procedures',
      },
      {
        title2: 'Home Care',
        url2: '/home-services',
      },
      {
        title2: 'Pharmacy',
        url2: '/pharmacy',
      },
      {
        title2: 'Imaging Services',
        url2: '/imaging',
      },
      {
        title2: 'Lab Services',
        url2: '/lab-services',
      },
      {
        title2: 'Emergency Services',
        url2: '/emergency-services',
      },
      {
        title2: 'Ante Natal & Post Natal Services',
        url2: '/ante-natal-post-natal-services',
      },
      {
        title2: 'Maternity Services',
        url2: '/Maternity-services',
      },
      
    ],
  },
  {
    title2: 'Gallery',
    url2: '/photo-gallery',
    submenuItems2: [],
  },

  {
    title2: 'Contacts',
    url2: '/contact-us',
    submenuItems2: [],
  },

];


  