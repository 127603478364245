import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Import EmailJS library
import './AppointmentForm.css';
import WhatsAppButton2 from '../WhatsAppChat/WhatsAppButton2';

const services = [
  'General Consultation',
  'Specialist Consultation',
  'pediatric Clinic',
  'Pharmacy',
  'Well Baby Clinic',
  'Imaging Services',
  'Home Based Services',
  'Minor Procedures',
  'Emergency Services',
  'Lab Based Services',
  'Ante Natal & Post Natal',
];

const AppointmentForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    dateOfBirth: '',
    selectedServices: [],
    conditionMessage: '',
    alternativeContact: {
      altFirstName: '',
      altLastName: '',
      altPhoneNumber: '',
      relation: '',
    },
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleServiceChange = (serviceName) => {
    setFormData((prevData) => {
      const isSelected = prevData.selectedServices.includes(serviceName);

      return {
        ...prevData,
        selectedServices: isSelected
          ? prevData.selectedServices.filter((service) => service !== serviceName)
          : [...prevData.selectedServices, serviceName],
      };
    });
  };

  const handleAlternativeContactChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      alternativeContact: {
        ...prevData.alternativeContact,
        [name]: value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email using EmailJS
    emailjs.sendForm('service_110f45c', 'template_bxfiy2o', e.target, 'y-g9Jwl21c0gr0b92')
      .then((result) => {
        console.log(result.text);
        setSuccessMessage('Appointment request sent successfully!');
        setErrorMessage('');
        // Reset form data after successful submission
        setFormData({
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          dateOfBirth: '',
          selectedServices: [],
          conditionMessage: '',
          alternativeContact: {
            altFirstName: '',
            altLastName: '',
            altPhoneNumber: '',
            relation: '',
          },
        });
      }, (error) => {
        console.log(error.text);
        setErrorMessage('Error sending appointment request. Please try again later.');
        setSuccessMessage('');
        // Handle errors here
      });
  };

  return (
    <div className="appointment-formContainer">
      <h2>Book an Appointment</h2>
      <form onSubmit={handleSubmit}>
        {/* Basic Information */}
        <div className="form-group">
          <label htmlFor="firstName">First Name*:</label>
          <input type="text" id="firstName" name="firstName" placeholder="Enter your first name" value={formData.firstName} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last Name*:</label>
          <input type="text" id="lastName" name="lastName" placeholder="Enter your last name" value={formData.lastName} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Your Phone Number*:</label>
          <input type="tel" id="phoneNumber" name="phoneNumber" placeholder="Enter your phone number" value={formData.phoneNumber} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Your Email:</label>
          <input type="email" id="email" name="email" placeholder="Enter your email" value={formData.email} onChange={handleChange} pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" />
        </div>
        <div className="form-group">
          <label htmlFor="dateOfBirth">Date of Birth*:</label>
          <input type="date" id="dateOfBirth" name="dateOfBirth" placeholder="Select your date of birth" value={formData.dateOfBirth} onChange={handleChange} required />
        </div>

        {/* Healthcare Services */}
        <div className="form-group">
          <label>Which Healthcare Service(s) are you interested in?</label>
          {services.map((service) => (
            <div key={service} className="checkbox-group">
              <input
                type="checkbox"
                id={service}
                name={service}
                checked={formData.selectedServices.includes(service)}
                onChange={() => handleServiceChange(service)}
              />
              <label htmlFor={service}>{service}</label>
            </div>
          ))}
        </div>

        {/* Condition Message */}
        <div className="form-group">
          <label htmlFor="conditionMessage">Tell Us more about your condition:</label>
          <textarea
            id="conditionMessage"
            name="conditionMessage"
            placeholder="Enter details about your condition"
            value={formData.conditionMessage}
            onChange={handleChange}
            rows="4"
          ></textarea>
        </div>

        {/* Alternative Contact */}
        <div className="form-group">
          <h3>Alternative Contact</h3>
          <div className="form-group">
            <label htmlFor="altFirstName">First Name:</label>
            <input
              type="text"
              id="altFirstName"
              name="altFirstName"
              placeholder="Enter first name"
              value={formData.alternativeContact.altFirstName}
              onChange={handleAlternativeContactChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="altLastName">Last Name:</label>
            <input
              type="text"
              id="altLastName"
              name="altLastName"
              placeholder="Enter last name"
              value={formData.alternativeContact.altLastName}
              onChange={handleAlternativeContactChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="altPhoneNumber">Phone Number:</label>
            <input
              type="tel"
              id="altPhoneNumber"
              name="altPhoneNumber"
              placeholder="Enter phone number"
              value={formData.alternativeContact.altPhoneNumber}
              onChange={handleAlternativeContactChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="relation">Relation:</label>
            <input
              type="text"
              id="relation"
              name="relation"
              placeholder="Enter your relation"
              value={formData.alternativeContact.relation}
              onChange={handleAlternativeContactChange}
            />
          </div>
        </div>

        {/* Submit Button */}
        <button type="submit">Submit</button>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>
      <WhatsAppButton2 />
    </div>
  );
};

export default AppointmentForm;
