import React, { useEffect, useRef } from 'react';
import './AntePostNatalPage.css'; // Import CSS for styling
import AOS from 'aos'; // Import AOS library for animations
import 'aos/dist/aos.css'; // Import AOS CSS

import antePostNatalImage from '../../../../Assets/images/parkVimages/pregnancycare.jpg'; // Import Ante Post Natal image

const AntePostNatalPage = () => {
  const antePostNatalRef = useRef();

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  // Scroll animation effect
  useEffect(() => {
    const antePostNatalElement = antePostNatalRef.current;

    const onScroll = () => {
      const offset = window.scrollY + window.innerHeight;

      if (offset > antePostNatalElement.offsetTop) {
        antePostNatalElement.classList.add('fade-up');
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className="antePostNatalPageContainer" ref={antePostNatalRef} data-aos="fade-up">
      <div className="antePostNatalImageContainer">
        <img src={antePostNatalImage} alt="Ante Natal & Post Natal" className="antePostNatalImage" />
      </div>
      <div className="antePostNatalContent">
        <h2 className="antePostNatalTitle">Ante Natal & Post Natal</h2>
        <p className="antePostNatalDescription">
          We have options to suit all, covering all stages from pregnancy through to the early weeks after birth. At ParkVille Medical Center, we offer comprehensive Ante Natal and Post Natal care to support women throughout their pregnancy journey and during the transition to motherhood. Our team of experienced obstetricians, midwives, and support staff provides personalized care, education, and guidance to ensure the health and well-being of both mother and baby.
          During the Ante Natal period, we offer regular check-ups, prenatal screenings, and prenatal classes to monitor the health of the mother and the development of the baby. We provide support for managing discomfort, addressing concerns, and preparing for labor and delivery. After childbirth, our Post Natal care focuses on recovery, breastfeeding support, newborn care, and postpartum health assessments. We are committed to providing compassionate care and empowering women to have a positive pregnancy and childbirth experience.
        </p>
        <button className="bookAntePostNatalButton">
          <a href="/book-appointment" target="_blank" rel="noopener noreferrer" className="buttonLink">
            Book Ante/Post Natal Care
          </a>
        </button>
      </div>
    </div>
  );
};

export default AntePostNatalPage;
