import React from 'react';

import HeroSection from '../heroSection/hero';
import AboutUs from '../about/About';
import ScrollToTopButton from '../ScrollToTopButton';
import OurServices from '../ServiceSection/OurServices';
import WhyChooseUs1 from '../WhyChooseus/WhyChooseUs';
import CallTodayButton from '../CallToday/CallTodayButton';
import WhatsAppButton2 from '../WhatsAppChat/WhatsAppButton2';
import Testimonials from './testimonials/testimonials';
import SpecialConsultationSlider from '../specialConsultationServices/SpecialConsultationSlider';

const Home = () => {

    // const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    // const handleToggleSidebar = () => {
    //   setIsSidebarOpen(!isSidebarOpen);
    // };

    return (
        <>
            {/* <ContactBar />
            <Navbar onToggleSidebar={handleToggleSidebar} />
            {isSidebarOpen && <Sidebar />} */}
        
        <div className="content-container">
            <HeroSection />
            <AboutUs />
            <WhyChooseUs1 />
            <OurServices />
            <CallTodayButton />
            <WhatsAppButton2 />
            <ScrollToTopButton />
            <Testimonials />
            <SpecialConsultationSlider />

        
        </div>
        </>
    );
}

export default Home;
