import React, { useEffect, useRef } from 'react';
import './labServicesPage.css'; // Import CSS for styling
import AOS from 'aos'; // Import AOS library for animations
import 'aos/dist/aos.css'; // Import AOS CSS

import labServicesImage from '../../../../Assets/images/new park images/best lab (1).jpeg'; // Import lab services image

const LabServicesPage = () => {
  const labServicesRef = useRef();

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  // Scroll animation effect
  useEffect(() => {
    const labServicesElement = labServicesRef.current;

    const onScroll = () => {
      const offset = window.scrollY + window.innerHeight;

      if (offset > labServicesElement.offsetTop) {
        labServicesElement.classList.add('fade-up');
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className="labServicesPageContainer" ref={labServicesRef} data-aos="fade-up">
      <div className="labServicesImageContainer">
        <img src={labServicesImage} alt="Lab-Based Services" className="labServicesImage" />
      </div>
      <div className="labServicesContent">
        <h2 className="labServicesTitle">Lab-Based Services</h2>
        <p className="labServicesDescription">
          We offer a comprehensive range of integrated solutions, including preclinical activities, Phase I-IV clinical trials, bioanalytical, and pharmaceutical development. At ParkVille Medical Center, our state-of-the-art laboratories are equipped with advanced technology and staffed by experienced professionals to deliver accurate and reliable results.
          Our lab-based services support various research and development initiatives, from drug discovery to regulatory submissions. With a commitment to quality and compliance, we ensure that our clients receive the highest standard of service and support throughout the drug development process.
          Partner with ParkVille for comprehensive lab-based solutions that drive innovation, efficiency, and success in healthcare and pharmaceutical industries.
        </p>
        <button className="bookLabServicesButton">
          <a href="/book-appointment" target="_blank" rel="noopener noreferrer" className="buttonLink">
            Book Lab Services
          </a>
        </button>
      </div>
    </div>
  );
};

export default LabServicesPage;
