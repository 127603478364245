// Navbar.js

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Sidebar from '../sidebar/Sidebar';
import { menuItems } from './navdata';
import './Navbar.css';
import logo from '../../Assets/logos/parkville logo.jpg';

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMediumScreen, setIsMediumScreen] = useState(false);
  const [activeSubmenuIndex, setActiveSubmenuIndex] = useState(null);
  const [isNavbarTransparent, setIsNavbarTransparent] = useState(true);

  const location = useLocation();

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMediumScreen(window.innerWidth <= 991);
    };

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const navbarHeight = document.querySelector('.navbar1').offsetHeight;

      setIsNavbarTransparent(scrollPosition <= navbarHeight);
    };

    checkScreenSize();
    handleScroll();

    window.addEventListener('resize', checkScreenSize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSubmenuOpen = (index) => {
    setActiveSubmenuIndex(index);
  };

  const handleSubmenuClose = () => {
    setActiveSubmenuIndex(null);
  };

  return (
    <>
      {isSidebarOpen && <Sidebar isOpen={isSidebarOpen} onCloseSidebar={handleToggleSidebar} />}
      <nav className={`navbar1 ${isNavbarTransparent ? 'transparent' : ''}`}>
      <div className="logo-container1">
        <Link to="/" className="home-link">
          <img src={logo} alt="Logo" className="logo-image" />
          <span className="company-name">Park Ville Medical Centre</span>
        </Link>
      </div>
        {isMediumScreen && (
          <div className="hamburger-menu" onClick={handleToggleSidebar}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        )}
        <ul className="menu1">
          {menuItems.map((menuItem, index) => (
            <li
              key={index}
              className="menu-item1"
              onMouseEnter={() => handleSubmenuOpen(index)}
              onMouseLeave={handleSubmenuClose}
            >
              <Link
                to={menuItem.url}
                className={location.pathname === menuItem.url ? 'active2' : ''}
              >
                {menuItem.title}
              </Link>
              {menuItem.submenuItems.length > 0 && (
                <ul className={`submenu1 ${activeSubmenuIndex === index ? 'submenu-open1' : ''}`}>
                  {menuItem.submenuItems.map((submenuItem, subIndex) => (
                    <li key={subIndex} className="submenu-item1">
                      <Link
                        to={submenuItem.url}
                        className={location.pathname === submenuItem.url ? 'active2' : ''}
                      >
                        {submenuItem.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
