import React, { useEffect, useRef } from 'react';
import './PediatricPage.css'; // Import CSS for styling
import AOS from 'aos'; // Import AOS library for animations
import 'aos/dist/aos.css'; // Import AOS CSS

import pediatricImage from '../../../../Assets/images/new park images/pediatric kids.jpeg'; // Import pediatric image

const PediatricPage = () => {
  const pediatricRef = useRef();

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  // Scroll animation effect
  useEffect(() => {
    const pediatricElement = pediatricRef.current;

    const onScroll = () => {
      const offset = window.scrollY + window.innerHeight;

      if (offset > pediatricElement.offsetTop) {
        pediatricElement.classList.add('fade-up');
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className="pediatricPageContainer" ref={pediatricRef} data-aos="fade-up">
      <div className="pediatricImageContainer">
        <img src={pediatricImage} alt="Pediatric" className="pediatricImage" />
      </div>
      <div className="pediatricContent">
        <h2 className="pediatricTitle">Pediatric Services</h2>
        <p className="pediatricDescription">
          Choosing the right pediatrician is one of the most important things you can do for your child. At Park Ville Medical Center, we go beyond taking care of children when they are ill; we emphasize maintaining their health and monitoring their growth and development from infancy into early adulthood. We deliver compassionate, personalized care to children of all ages, from infancy into young adulthood. We offer advanced care and emergency services for the best care of your child while working with your family to address your medical and emotional concerns.
          Our team helps manage chronic medical conditions and diseases to ensure your child grows up healthy and develops properly. The vision of our Pediatric care is to offer integrated services useful in the prevention and management of childhood illness.
        </p>
        <button className="bookAppointmentButton">
          <a href="/book-appointment" target="_blank" rel="noopener noreferrer">
            Book Appointment
          </a>
        </button>
      </div>
    </div>
  );
};

export default PediatricPage;
