// WhatsAppChatPopup.jsx
import React from 'react';
import WhatsAppButton2 from './WhatsAppButton2';
import { FaTimes } from 'react-icons/fa';
import parkvilleLogo from '../../Assets/logos/parkville logo.jpg';
import whatsappBackground from '../../Assets/logos/WhatsApp-chat-Background.png';
import onlineDot from '../../Assets/logos/green-texture.jpg'; // Import the online dot image
import './WhatsAppChatPopup.css';

const WhatsAppChatPopup = ({ isOpen, onClose }) => {
    const handleWhatsAppClick = () => {
        // Logic to open WhatsApp for chat
        // Replace 'number' with your WhatsApp number
        window.open('https://wa.me/+254753681716', '_blank');
      };
  return (
    <div className={`whatsappChatPopup ${isOpen ? 'open' : ''}`}>
      <div className="chat-header">
        <div className="header-logo-container">
          <img src={parkvilleLogo} alt="Parkville Medical Center" className="header-logo" />
          {/* <img src={onlineDot} alt="Online" className="online-dot" /> */}
        </div>
        <div className="ChatheaderText">
          <h1>Park Ville Medical Center</h1>
          <p>Typically replies within an hour.</p>
        </div>
        <button className="ChartcloseButton" onClick={onClose}>
          <FaTimes />
        </button>
      </div>
      <div className="chat-body" style={{ backgroundImage: `url(${whatsappBackground})` }}>
        <div className="reply">
          <p>Hello there! Welcome to Park Ville Medical Center.</p>
        </div>
        <div className="reply">
          <p>Click the start button below.</p>
        </div>
      </div>
      <div className="chat-footer">
        <WhatsAppButton2 />
        <button className="startChatButton" onClick={handleWhatsAppClick} >
          Start Chat
        </button>
      </div>
    </div>
  );
};

export default WhatsAppChatPopup;
