// WhatsAppButton2.jsx
import React, { useState } from 'react';
import WhatsAppChatPopup from './WhatsAppChatPopup';
import whatsappIcon from '../../Assets/logos/whatsapp-logo.png';
import './WhatsAppButton2.css';

const WhatsAppButton2 = () => {
  const [isChatPopupOpen, setIsChatPopupOpen] = useState(false);

  const handleWhatsAppClick = () => {
    setIsChatPopupOpen(true);
  };

  const handleCloseChatPopup = () => {
    setIsChatPopupOpen(false);
  };

  return (
    <div className="whatsappButtonContainer">
      <div className="whatsappButton" onClick={handleWhatsAppClick}>
        <button className="whatsappIcon2">
          <img src={whatsappIcon} alt="WhatsApp" />
        </button>
        <span className="whatsappText2">WhatsApp us here.</span>
      </div>

      {isChatPopupOpen && <WhatsAppChatPopup isOpen={isChatPopupOpen} onClose={handleCloseChatPopup} />}
    </div>
  );
};

export default WhatsAppButton2;
