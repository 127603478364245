import React, { useEffect, useRef } from 'react';
import './SpecialistConsultationPage.css'; // Import CSS for styling
import AOS from 'aos'; // Import AOS library for animations
import 'aos/dist/aos.css'; // Import AOS CSS

import specialistConsultationImage from '../../../../Assets/images/new park images/special consoltation.jpg'; // Import specialist consultation image
import SpecialConsultationSlider from '../../../specialConsultationServices/SpecialConsultationSlider';

const SpecialistConsultationPage = () => {
  const specialistConsultationRef = useRef();

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  // Scroll animation effect
  useEffect(() => {
    const specialistConsultationElement = specialistConsultationRef.current;

    const onScroll = () => {
      const offset = window.scrollY + window.innerHeight;

      if (offset > specialistConsultationElement.offsetTop) {
        specialistConsultationElement.classList.add('fade-up');
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <>
      <div className="specialistConsultationPageContainer" ref={specialistConsultationRef} data-aos="fade-up">
        <div className="specialistConsultationImageContainer">
          <img src={specialistConsultationImage} alt="Specialist Consultation" className="specialistConsultationImage" />
        </div>
        <div className="specialistConsultationContent">
          <h2 className="specialistConsultationTitle">Specialist Consultation</h2>
          <p className="specialistConsultationDescription">
            Special clinic with substantial premium care services & quality standards. At ParkVille Medical Center, we offer specialized consultations with expert physicians and healthcare professionals across various medical specialties. From cardiology to orthopedics, our specialists provide comprehensive evaluations, personalized treatment plans, and ongoing care to address your unique health needs.
            We prioritize accessibility and quality health care. Experience compassionate care, advanced diagnostics, and effective treatments tailored to your specific medical conditions. Trust ParkVille for specialized healthcare solutions that prioritize your well-being and recovery.
          </p>
          {/* <button className="bookSpecialistConsultationButton">
            <a href="/book-appointment" target="_blank" rel="noopener noreferrer" className="buttonLink">
              Book Specialist Consultation
            </a>
          </button> */}
        </div>
      </div>
      <SpecialConsultationSlider />
    </>
  );
};

export default SpecialistConsultationPage;
