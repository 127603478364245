import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css'; // Assuming this file contains global styles
import Home from './Components/pages/Home';
import Navbar from '../src/Components/navSection/NavBar';
import Footer from './Components/footerSection/Footer';
import AboutPage from './Components/pages/Aboutpage/AboutPag';
import AppointmentForm from './Components/BookAppointment/AppointmentForm';
import LoadTimeIndicator from './Components/loadIndicator/LoadTimeIndicator';
import PharmacyPage from './Components/pages/servicesPages/phamarcyPage/pharmacyPage';
import PediatricPage from './Components/pages/servicesPages/pediatricPage/PediatricPage';
import WellBabyClinicPage from './Components/pages/servicesPages/WellBabyClinicPage/WellBabyClinicPage';
import ImagingServicesPage from './Components/pages/servicesPages/ImagingServicesPage/ImagingServicesPage';
import MinorProceduresPage from './Components/pages/servicesPages/MinorProceduresPage/MinorProceduresPage';
import HomeBasedServicesPage from './Components/pages/servicesPages/HomeBasedServicesPage/HomeBasedServicesPage';
import GeneralConsultationPage from './Components/pages/servicesPages/GeneralConsultationPage/GeneralConsultationPage';
import SpecialistConsultationPage from './Components/pages/servicesPages/SpecialistConsultationPage/SpecialistConsultationPage';
import LabServicesPage from './Components/pages/servicesPages/labPage/LabServicesPage ';
import EmergencyServicesPage from './Components/pages/servicesPages/EmergencyServicesPage/EmergencyServicesPage';
import AntePostNatalPage from './Components/pages/servicesPages/AntePostNatalPage/AntePostNatalPage';
import ContactPage from './Components/ContactPage/Contact';
import NotFoundPage from './Components/pages/NotFoundPage/NotFoundPage';
import PhotoGallery from './Components/pages/PhotoGallery/PhotoGallery';
import ContactBar from './Components/contactbar/Contactbar';

const App = () => {
    return (
        <>
            <LoadTimeIndicator /> {/* Include the LoadTimeIndicator component here */}
            <Navbar />
            <ContactBar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about-clinic" element={<AboutPage />} />
                <Route path="/book-appointment" element={<AppointmentForm/>} />
                <Route path="/pharmacy" element={<PharmacyPage/>} />
                <Route path="/pediatric" element={<PediatricPage/>} />
                <Route path="/well-baby-clinic" element={<WellBabyClinicPage/>} />
                <Route path="/imaging" element={<ImagingServicesPage/>} />
                <Route path="/minor-procedures" element={<MinorProceduresPage/>} />
                <Route path="/home-services" element={<HomeBasedServicesPage/>} /> 
                <Route path="/general-consultation/services" element={<GeneralConsultationPage/>} /> 
                <Route path="/specialist-consultation" element={<SpecialistConsultationPage/>} /> 
                <Route path="/specialist-consultation" element={<SpecialistConsultationPage/>} /> 
                <Route path="/lab-services" element={<LabServicesPage/>} /> 
                <Route path="/emergency-services" element={<EmergencyServicesPage/>} />
                <Route path="/ante-natal-post-natal-services" element={<AntePostNatalPage/>} />

                <Route path="/photo-gallery" element={<PhotoGallery/>} />
                
                <Route path="/contact-us" element={<ContactPage/>} />

                 {/* Always keep the NotFoundPage route at the end */}
                <Route path="*" element={<NotFoundPage />} />

            </Routes>
            <Footer />
        </>
    );
}

export default App;
