import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './PhotoGallery.css';

import { FaPause, FaPlay, FaHeart, FaDownload, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import Image1 from '../../../Assets/images/parkVimages/receptionist.jpg';
import Image2 from '../../../Assets/images/parkVimages/clients been served.jpg';
import Image3 from '../../../Assets/images/parkVimages/customer and reception.jpg';
import Image4 from '../../../Assets/images/new park images/special consoltation.jpg';
import Image5 from '../../../Assets/images/parkVimages/bestservice.jpg';
import Image6 from '../../../Assets/images/parkVimages/slider2.jpg';
import Image7 from '../../../Assets/images/new park images/ante natal.jpeg';
import Image8 from '../../../Assets/images/parkVimages/Slider3.jpg';
import Image9 from '../../../Assets/images/parkVimages/homecare (2).jpg';
import Image10 from '../../../Assets/images/parkVimages/generalconsultation.jpg';
import Image11 from '../../../Assets/images/new park images/ent.png';
import Image12 from '../../../Assets/images/new park images/best lab (1).jpeg';
import Image13 from '../../../Assets/images/new park images/best phamercy.jpeg';
import Image14 from '../../../Assets/images/new park images/best reception.jpg';
import Image15 from '../../../Assets/images/new park images/consultation office.jpeg';
import Image16 from '../../../Assets/images/new park images/gaenacologist.jpeg';
import Image17 from '../../../Assets/images/new park images/give birth.jpeg';
import Image18 from '../../../Assets/images/new park images/pediatric kids.jpeg';
import Image19 from '../../../Assets/images/new park images/physiotherapist-performing-therapeutic-massage-male-client.jpg';
import Image20 from '../../../Assets/images/new park images/woman-getting-vaccinated-close-up.jpg';
import Image21 from '../../../Assets/images/new park images/best lab image.jpeg';
import Image22 from '../../../Assets/images/new park images/hospital picture 2.jpeg';
const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10, Image11, Image12, Image13, Image14, Image15, Image16, Image17, Image18, Image19, Image20, Image21, Image22];

const PhotoGallery = () => {
  const [isPlaying, setIsPlaying] = useState(true);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const handleLike = () => {
    // Perform like action (You can replace this with your desired functionality)
    console.log('Liked!');
  };

  const handleDownload = () => {
    // Perform download action (You can replace this with your desired functionality)
    console.log('Downloaded!');
  };

  return (
    <div className="photoGalleryContainer2">
      <Carousel
        autoPlay={isPlaying}
        infiniteLoop
        showArrows
        showIndicators = {false}
        showThumbs={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev && (
          <button type="button" onClick={onClickHandler} title={label} className="carousel-arrow prev">
            <FaChevronLeft />
          </button>
        )}
        renderArrowNext={(onClickHandler, hasNext, label) => hasNext && (
          <button type="button" onClick={onClickHandler} title={label} className="carousel-arrow next">
            <FaChevronRight />
          </button>
        )}
      >
        {images.map((image, index) => (
          <div key={index} className="galleryItem">
            <img src={image} alt={`Image ${index + 1}`} className="galleryImage2" />
            {/* <div className="caption">
              Caption for Image {index + 1}
            </div> */}
            <div className="controls">
              <button className="control-btn" onClick={togglePlay}>
                {isPlaying ? <FaPause /> : <FaPlay />}
              </button>
              <button className="control-btn" onClick={handleLike}>
                <FaHeart />
              </button>
              <button className="control-btn" onClick={handleDownload}>
                <FaDownload />
              </button>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default PhotoGallery;
