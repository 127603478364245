import React, { useEffect, useRef } from 'react';
import './MinorProceduresPage.css'; // Import CSS for styling
import AOS from 'aos'; // Import AOS library for animations
import 'aos/dist/aos.css'; // Import AOS CSS

import minorProceduresImage from '../../../../Assets/images/parkVimages/minorProcedure.jpg'; // Import minor procedures image

const MinorProceduresPage = () => {
  const minorProceduresRef = useRef();

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  // Scroll animation effect
  useEffect(() => {
    const minorProceduresElement = minorProceduresRef.current;

    const onScroll = () => {
      const offset = window.scrollY + window.innerHeight;

      if (offset > minorProceduresElement.offsetTop) {
        minorProceduresElement.classList.add('fade-up');
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className="minorProceduresPageContainer" ref={minorProceduresRef} data-aos="fade-up">
      <div className="minorProceduresImageContainer">
        <img src={minorProceduresImage} alt="Minor Procedures" className="minorProceduresImage" />
      </div>
      <div className="minorProceduresContent">
        <h2 className="minorProceduresTitle">Minor Procedures</h2>
        <p className="minorProceduresDescription">
          Minor surgical procedures are minimally invasive interventions performed with small incisions, enabling surgical tools to be inserted into the body. At ParkVille, our skilled medical team conducts a variety of minor procedures aimed at diagnosing, treating, and managing various medical conditions effectively. From biopsies to abscess drainage and minor skin surgeries, we ensure patient comfort, safety, and optimal outcomes.
        </p>
        <button className="bookAppointmentButton">
          <a href="/book-appointment" target="_blank" rel="noopener noreferrer">
            Book Appointment
          </a>
        </button>

      </div>
    </div>
  );
};

export default MinorProceduresPage;
