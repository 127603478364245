import React, { useEffect, useRef } from 'react';
import './HomeBasedServicesPage.css'; // Import CSS for styling
import AOS from 'aos'; // Import AOS library for animations
import 'aos/dist/aos.css'; // Import AOS CSS

import homeBasedImage from '../../../../Assets/images/parkVimages/homecare (2).jpg'; // Import home-based services image

const HomeBasedServicesPage = () => {
  const homeBasedRef = useRef();

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  // Scroll animation effect
  useEffect(() => {
    const homeBasedElement = homeBasedRef.current;

    const onScroll = () => {
      const offset = window.scrollY + window.innerHeight;

      if (offset > homeBasedElement.offsetTop) {
        homeBasedElement.classList.add('fade-up');
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className="homeBasedPageContainer" ref={homeBasedRef} data-aos="fade-up">
      <div className="homeBasedImageContainer">
        <img src={homeBasedImage} alt="Home Based Services" className="homeBasedImage" />
      </div>
      <div className="homeBasedContent">
        <h2 className="homeBasedTitle">Home Based Services</h2>
        <p className="homeBasedDescription">
          We have a pool of certified home nursing professionals who are qualified, skilled, and committed to providing the best care. All are trained from reputable institutions. ParkVille offers a wide range of home-based medical services, including nursing care, wound care, medication management, and more. Our dedicated team ensures that patients receive personalized and compassionate care in the comfort of their homes.
        </p>
        <button className="bookAppointmentButton">
          <a href="/book-appointment" target="_blank" rel="noopener noreferrer">
            Book Appointment
          </a>
        </button>
      </div>
    </div>
  );
};

export default HomeBasedServicesPage;
