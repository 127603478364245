// WhyChooseUs.js
import React, { useState, useEffect } from 'react';
import { FaHeartbeat, FaShieldAlt, FaHospital } from 'react-icons/fa';
import { RiStethoscopeLine } from 'react-icons/ri';
import './WhyChooseUs.css'; // Assuming you have a CSS file for styling

const Counter = ({ value, label }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (count < value) {
        setCount(prevCount => prevCount + 1);
      } else {
        clearInterval(timer);
      }
    }, 100); // Adjust the speed of the counter

    return () => clearInterval(timer);
  }, [count, value]);

  return (
    <div className="counter">
      <div className="counter-value">{count}</div>
      <div className="counter-label">{label}</div>
    </div>
  );
};

const WhyChooseUs = () => {
  return (
    <section className="why-choose-usSection">
      <div className="background-image"></div> {/* Background image */}
      <div className="choose-us-container">
        <h2>Why Choose Us</h2>
        <div className="reasons">
          <div className="reason">
            <FaHeartbeat className="icon" />
            <h3>Quality Medical Care</h3>
            <p>We prioritize your well-being and ensure you receive the highest quality of service.</p>
          </div>
          <div className="reason">
            <FaShieldAlt className="icon" />
            <h3>Quality Policy</h3>
            <p>We are dedicated to providing quality medical care. We adhere to the industry stipulated policies and quality control measures to ensure high standards in our services.</p>
          </div>
          <div className="reason">
            <FaHospital className="icon" />
            <h3>State-of-the-Art Facilities</h3>
            <p>Equipped with modern medical technology and state-of-the-art facilities, we ensure accurate diagnoses and effective treatments for all medical conditions.</p>
          </div>
          <div className="reason">
            <RiStethoscopeLine className="icon" />
            <h3>Experienced and Compassionate Staff</h3>
            <p>Our team consists of experienced healthcare professionals who are dedicated to providing compassionate care to every patient.</p>
          </div>
        </div>
        <div className="counters">
          <Counter value={5} label="Maternity Beds" />
          <Counter value={24} label="Hour Pharmacy" />
          <Counter value={24} label="Hour Accident & Emergency Services" />
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
