import React, { useEffect, useRef } from 'react';
import './EmergencyServicesPage.css'; // Import CSS for styling
import AOS from 'aos'; // Import AOS library for animations
import 'aos/dist/aos.css'; // Import AOS CSS

import emergencyImage from '../../../../Assets/images/parkVimages/emergency.jpg'; // Import emergency service image

const EmergencyServicesPage = () => {
  const emergencyRef = useRef();

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  // Scroll animation effect
  useEffect(() => {
    const emergencyElement = emergencyRef.current;

    const onScroll = () => {
      const offset = window.scrollY + window.innerHeight;

      if (offset > emergencyElement.offsetTop) {
        emergencyElement.classList.add('fade-up');
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className="emergencyPageContainer" ref={emergencyRef} data-aos="fade-up">
      <div className="emergencyImageContainer">
        <img src={emergencyImage} alt="Emergency Services" className="emergencyImage" />
      </div>
      <div className="emergencyContent">
        <h2 className="emergencyTitle">Emergency Services</h2>
        <p className="emergencyDescription">
          We specialize in emergency services. Our emergency department handles all emergency cases and a wide range of diagnostic tests and minor surgical procedures. Our team of dedicated healthcare professionals is available around the clock to provide immediate medical attention and urgent care for patients in need. At ParkVille Medical Center, we prioritize rapid response, accurate diagnosis, and effective treatment to ensure the best possible outcomes for our patients in critical situations.
          With state-of-the-art facilities and advanced medical technologies, we deliver prompt and comprehensive emergency care to address a variety of medical emergencies, from trauma and injuries to acute illnesses and critical conditions. Trust ParkVille for compassionate and efficient emergency services when you need them most.
        </p>
        <button className="bookEmergencyButton">
          <a href="/book-appointment" target="_blank" rel="noopener noreferrer" className="buttonLink">
            Book Emergency Service
          </a>
        </button>
      </div>
    </div>
  );
};

export default EmergencyServicesPage;
