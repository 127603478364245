import React, { useEffect, useRef } from 'react';
import './ImagingServicesPage.css'; // Import CSS for styling
import AOS from 'aos'; // Import AOS library for animations
import 'aos/dist/aos.css'; // Import AOS CSS

import imagingImage from '../../../../Assets/images/parkVimages/imagecheck.jpg'; // Import imaging service image

const ImagingServicesPage = () => {
  const imagingRef = useRef();

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  // Scroll animation effect
  useEffect(() => {
    const imagingElement = imagingRef.current;

    const onScroll = () => {
      const offset = window.scrollY + window.innerHeight;

      if (offset > imagingElement.offsetTop) {
        imagingElement.classList.add('fade-up');
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className="imagingPageContainer" ref={imagingRef} data-aos="fade-up">
      <div className="imagingImageContainer">
        <img src={imagingImage} alt="Imaging Services" className="imagingImage" />
      </div>
      <div className="imagingContent">
        <h2 className="imagingTitle">Imaging Services</h2>
        <p className="imagingDescription">
          ParkVille offers comprehensive, state-of-the-art medical imaging services to provide early detection, precise diagnosis, and effective treatment plans for our patients. Our advanced imaging technologies include MRI, CT scans, X-rays, ultrasound, and more, all operated by experienced radiologists and technologists.
          With a focus on accuracy and patient comfort, we deliver prompt results to support timely medical decisions. From routine screenings to complex diagnostic procedures, trust ParkVille for reliable imaging services in a caring and convenient outpatient setting.
        </p>
        <button className="bookAppointmentButton">
          <a href="your_booking_page_url_here" target="_blank" rel="noopener noreferrer">
            Book Appointment
          </a>
        </button>
      </div>
    </div>
  );
};

export default ImagingServicesPage;
