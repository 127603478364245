import React, { useEffect, useRef } from 'react';
import './WellBabyClinicPage.css'; // Import CSS for styling
import AOS from 'aos'; // Import AOS library for animations
import 'aos/dist/aos.css'; // Import AOS CSS

import wellBabyImage from '../../../../Assets/images/new park images/high-angle-father-tickling-girl.jpg'; // Import well baby image

const WellBabyClinicPage = () => {
  const wellBabyRef = useRef();

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  // Scroll animation effect
  useEffect(() => {
    const wellBabyElement = wellBabyRef.current;

    const onScroll = () => {
      const offset = window.scrollY + window.innerHeight;

      if (offset > wellBabyElement.offsetTop) {
        wellBabyElement.classList.add('fade-up');
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className="wellBabyPageContainer" ref={wellBabyRef} data-aos="fade-up">
      <div className="wellBabyImageContainer">
        <img src={wellBabyImage} alt="Well Baby Clinic" className="wellBabyImage" />
      </div>
      <div className="wellBabyContent">
        <h2 className="wellBabyTitle">Well Baby Clinic</h2>
        <p className="wellBabyDescription">
          We are committed to providing personalized high-quality care for your child right from birth all the way to young adulthood. Welcome to Park Ville Medical Center, where we prioritize the health and well-being of your little ones. Our dedicated team of healthcare professionals is committed to providing exceptional care and support to infants and their families.
          At our Well Baby Clinic, we understand that the early stages of a child’s life are critical for their overall development and growth. That’s why we offer comprehensive services tailored specifically to meet the unique needs of your baby. From routine check-ups to vaccinations and developmental screenings, we ensure that your child receives the highest standard of care in a warm and nurturing environment.
          Our experienced pediatricians and nurses are trained in the latest medical advancements and follow evidence-based practices to promote optimal health for your baby. We provide personalized care and guidance, addressing any concerns you may have about your child’s growth, nutrition, or milestones. Our team also emphasizes preventive care, offering valuable advice on parenting, nutrition, and child safety to help you create a healthy and nurturing home environment.
        </p>
        <button className="bookAppointmentButton">
          <a href="/book-appointment" target="_blank" rel="noopener noreferrer">
            Book Appointment
          </a>
        </button>
      </div>
    </div>
  );
};

export default WellBabyClinicPage;
