import React, { useEffect, useRef } from 'react';
import './PharmacyPage.css'; // Import CSS for styling
import AOS from 'aos'; // Import AOS library for animations
import 'aos/dist/aos.css'; // Import AOS CSS

import pharmacyImage from '../../../../Assets/images/new park images/best phamercy.jpeg'; // Import pharmacy image

const PharmacyPage = () => {
  const pharmacyRef = useRef();

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  // Scroll animation effect
  useEffect(() => {
    const pharmacyElement = pharmacyRef.current;

    const onScroll = () => {
      const offset = window.scrollY + window.innerHeight;

      if (offset > pharmacyElement.offsetTop) {
        pharmacyElement.classList.add('fade-up');
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className="pharmacyPageContainer" ref={pharmacyRef} data-aos="fade-up">
      <div className="pharmacyImageContainer">
        <img src={pharmacyImage} alt="Pharmacy" className="pharmacyImage" />
      </div>
      <div className="pharmacyContent">
        <h2 className="pharmacyTitle">Pharmacy</h2>
        <p className="pharmacyDescription">
          The Clinic pharmacy operates 24 hrs a day serving both outpatients and inpatients. All our pharmacies are certified and licensed by the Pharmacy and Poisons Board of Kenya. Our skilled staff members are dedicated to ensuring safe, timely, and effective drug prescriptions for all types of patient care. The pharmacies are always well stocked with affordable medicines to meet the demands of our customers.
        </p>
      </div>
    </div>
  );
};

export default PharmacyPage;
