export const menuItems = [

  {
    title: 'Home',
    url: '/',
    submenuItems: [],
  },
  {
    title: 'About Us',
    url: '/about-clinic',
    submenuItems: [],
  },

  {
    title: 'Services',
    url: '/#', 
    submenuItems: [
      {
        title: 'General Consultation',
        url: '/general-consultation/services',
      },
      {
        title: 'Specialist Consultation',
        url: '/specialist-consultation',
      },
      {
        title: 'Pediatric Clinic',
        url: '/pediatric',
      },
      {
        title: 'Well Baby Clinic',
        url: '/well-baby-clinic',
      },
      {
        title: 'Minor Procedures',
        url: '/minor-procedures',
      },
      {
        title: 'Home Care',
        url: '/home-services',
      },
      {
        title: 'Pharmacy',
        url: '/pharmacy',
      },
      {
        title: 'Imaging Services',
        url: '/imaging',
      },
      {
        title: 'Lab Based Services',
        url: '/lab-services',
      },
      {
        title: 'Emergency Services',
        url: '/emergency-services',
      },
      {
        title: 'Ante Natal & Post Natal Services',
        url: '/ante-natal-post-natal-services',
      },
      {
        title: 'Maternity Services',
        url: '/Maternity-services',
      },
      

  
    ],
  },
  {
    title: 'Gallery',
    url: '/photo-gallery',
    submenuItems: [],
  },

  {
    title: 'Contacts',
    url: '/contact-us',
    submenuItems: [],
  },

];
