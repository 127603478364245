import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SpecialConsultationSlider.css'; // Your custom CSS file for styling
import image1 from '../../Assets/images/new park images/man-being-interviewed-indoors-by-journalist.jpg';
import image2 from '../../Assets/images/new park images/close-up-fresh-juicy-fruits-front-female-dietician.jpg';
import image3 from '../../Assets/images/new park images/physiotherapist-performing-therapeutic-massage-male-client.jpg';
import image4 from '../../Assets/images/new park images/ent.png';
import image5 from '../../Assets/images/new park images/gastroenterologist.PNG';
import image6 from '../../Assets/images/new park images/wellbaby image1.PNG';
import image7 from '../../Assets/images/new park images/give birth.jpeg';

const services = [
  {
    title: 'Nutrition',
    description: 'Get personalized dietary guidance from our nutrition experts to help you achieve your health goals.',
    image: image2,
    link: '/book-appointment',
  },
  {
    title: 'Psychiatrist',
    description: 'Receive compassionate mental health services tailored to your needs from our experienced psychiatrists.',
    image: image1,
    link: '/book-appointment',
  },
  {
    title: 'Chiropractor',
    description: 'Experience relief from pain and discomfort through personalized chiropractic care.',
    image: image3,
    link: '/book-appointment',
  },
  {
    title: 'Ent (Ear Nose and Throat)',
    description: 'Receive expert care for ear, nose, and throat conditions from our specialized ENT doctors.',
    image: image4,
    link: '/book-appointment',
  },
  {
    title: 'Gastroenterologist',
    description: 'Find relief from digestive issues with personalized treatment plans from our gastroenterologists.',
    image: image5,
    link: '/book-appointment',
  },
  {
    title: 'Paediatrician',
    description: 'Ensure the well-being of your child with expert pediatric care and medical guidance.',
    image: image6,
    link: '/book-appointment',
  },
  {
    title: 'Obstetrics and Gynaecologist',
    description: 'Receive comprehensive obstetric and gynecological care from our experienced specialists.',
    image: image7,
    link: '/book-appointment',
  },
  // Add more services here
];

const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
      {/* Your custom previous arrow icon */}
      <span>&#8592;</span>
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-next-arrow" onClick={onClick}>
      {/* Your custom next arrow icon */}
      <span>&#8594;</span>
    </div>
  );
};

const SpecialConsultationSlider = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Adjust the number of services displayed per slide as needed
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for small devices
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <div className="special-consultation-slider-container5">
      <Slider {...sliderSettings}>
        {services.map((service, index) => (
          <div key={index} className="service-slide5">
            <div className="card5">
              <img src={service.image} alt={service.title} className="card-image5" />
              <div className="card-content5">
                <h3>{service.title}</h3>
                <p>{service.description}</p>
                <a href={service.link} className="book-appointment-btn">Book Appointment</a>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SpecialConsultationSlider;
