import React, { useEffect, useRef } from 'react';
import './AboutPage.css'; // Import CSS for styling
import AOS from 'aos'; // Import AOS library for animations
import 'aos/dist/aos.css'; // Import AOS CSS
import aboutUsImage from '../../../../src/Assets/images/new park images/best reception.jpg'; // Import About Us image
import SpecialConsultationSlider from '../../../Components/specialConsultationServices/SpecialConsultationSlider';

const AboutUsPage = () => {
  const aboutUsRef = useRef();

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  // Scroll animation effect
  useEffect(() => {
    const aboutUsElement = aboutUsRef.current;

    const onScroll = () => {
      const offset = window.scrollY + window.innerHeight;

      if (offset > aboutUsElement.offsetTop) {
        aboutUsElement.classList.add('fade-up');
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <>
    <div className="aboutUsPageContainer" ref={aboutUsRef} data-aos="fade-up">
      <div className="aboutUsImageContainer">
        <img src={aboutUsImage} alt="About Us" className="aboutUsImage" />
      </div>
      <div className="aboutUsContent">
        <h2 className="aboutUsTitle">We Care About Your Health</h2>
        <p className="aboutUsDescription">
          At Park Ville, we understand that accidents and illness can happen at any time. That's why we provide services and treatment at your convenience.
        </p>
        <p className="aboutUsDescription">
          We are dedicated to offering excellent and prompt urgent care services. Our compassionate and experienced staff ensures a timely diagnosis, accurate medical information, and a personalized touch. We work hard to guide you or your family member on the path to a swift recovery.
        </p>
        <p className="aboutUsDescription">
          Experience pride in professional service at Park Ville Medical Center. Our commitment to your well-being extends beyond mere treatment; it's about fostering trust, comfort, and confidence throughout your healthcare journey.
        </p>
        <p className="aboutUsDescription">
          Whether you're seeking routine check-ups, specialized care, or emergency services, Park Ville is your reliable partner for comprehensive healthcare solutions. Join us in prioritizing your health and wellness, because at Park Ville, your well-being is our foremost priority.
        </p>
        <button className="bookAntePostNatalButton">
          <a href="/book-appointment" target="_blank" rel="noopener noreferrer" className="buttonLink">
            Book Appointment
          </a>
        </button>
      </div>
    </div>
    <SpecialConsultationSlider />
    </>
  );
};


export default AboutUsPage;
