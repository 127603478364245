import React, { useEffect, useRef } from 'react';
import './GeneralConsultationPage.css'; // Import CSS for styling
import AOS from 'aos'; // Import AOS library for animations
import 'aos/dist/aos.css'; // Import AOS CSS

import consultationImage from '../../../../Assets/images/parkVimages/generalconsultation.jpg'; // Import consultation image

const GeneralConsultationPage = () => {
  const consultationRef = useRef();

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  // Scroll animation effect
  useEffect(() => {
    const consultationElement = consultationRef.current;

    const onScroll = () => {
      const offset = window.scrollY + window.innerHeight;

      if (offset > consultationElement.offsetTop) {
        consultationElement.classList.add('fade-up');
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className="consultationPageContainer" ref={consultationRef} data-aos="fade-up">
      <div className="consultationImageContainer">
        <img src={consultationImage} alt="General Consultation" className="consultationImage" />
      </div>
      <div className="consultationContent">
        <h2 className="consultationTitle">General Consultation</h2>
        <p className="consultationDescription">
          At ParkVille Medical Center, we provide comprehensive general consultations for individuals and families. Our experienced medical professionals offer personalized care and thorough assessments to address your health concerns and promote overall well-being. From routine check-ups to managing chronic conditions, we are dedicated to supporting your health journey at every stage.
          We strive to create a comfortable and welcoming environment where you can discuss any health issues openly. Our team emphasizes preventive care, health education, and lifestyle modifications to help you achieve and maintain optimal health. Trust ParkVille for trusted medical advice and compassionate care for you and your loved ones.
        </p>
        <button className="bookConsultationButton">
          <a href="/book-appointment" target="_blank" rel="noopener noreferrer" className="buttonLink">
            Book Consultation
          </a>
        </button>
      </div>
    </div>
  );
};

export default GeneralConsultationPage;
